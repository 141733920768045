import { H5, Separator } from '@web/atomic';
import * as React from 'react';
import { EmojiBall, EmojiBallProps } from '../atm.emoji-ball';
import { EmojiTextVerticalStyled } from './emoji-text-vertical.component.style';

export interface EmojiTextVerticalProps extends EmojiBallProps {
  text: string;
}

export const EmojiTextVertical: React.FunctionComponent<EmojiTextVerticalProps> = (props) => (
  <EmojiTextVerticalStyled>
    <EmojiBall emoji={props.emoji} forbidden={props.forbidden} />
    <Separator />
    <H5>{props.text}</H5>
  </EmojiTextVerticalStyled>
);
