import { Assesment } from '@global/utils/remote-graphql-types';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import { EmojiTextVertical } from '@lp-root/src/components/mol.emoji-text-vertical/emoji-text-vertical.component';
import IndexLayout from '@lp-root/src/components/org.layout/layout.component';
import { AssesmentReport, AssesmentResultsStartTreatmentTitle } from '@lp-root/src/modules/assesment/assesment-report.component';
import { useAssesment } from '@lp-root/src/modules/assesment/use-assesment';
import { appPaths } from '@lp-root/src/utils/path';
import { PageProps } from '@lp-src/utils/local-types';
import { Body, Col, FeaturedH2, Grid, H1, H3, Row, Separator } from '@web/atomic';
import { FeaturedWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import { SeeMoreBox } from '@web/atomic/mol.see-more-box';
import { sendDataLayer } from '@web/utils/analytics';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { SleepCondition } from '@lp-root/src/data/quiz-typeform.model';

const ResultsPage: React.FunctionComponent<PageProps> = (props) => {
  const assesment = useAssesment(true);

  // Obs: you can use this Object to test this page if needed
  // const assesment = {
  //   id: 0,
  //   timestamp: '',
  //   age: SleepProblemAge.MoreThanOneYear,
  //   cause: SleepProblemCause.PhysicalPain,
  //   challenge: SleepProblemChallenge.FallSleep,
  //   condition: `${SleepCondition.Apnea};${SleepCondition.Bipolar}`,
  //   email: 'teste@teste.com',
  //   first_name: 'first_name',
  //   frequency: SleepProblemFrequency.BetweenOnendTwo,
  //   last_name: 'last_name',
  //   solution: SleepSolution.Doctor,
  //   subscribe: true,
  //   time_to_sleep: TimeInterval.Till15,
  //   wake_up_early: TimeInterval.Till15,
  //   wake_up_middle: TimeInterval.Till15
  // }

  React.useEffect(() => {
    pushDatalayer(assesment);
  }, [assesment]);

  return (
    <IndexLayout {...props}>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Resultados do questionário de insônia',
          description: 'Entenda sua insônia e confira uma dica personalizada que preparamos pra você',
        }}
      />

      <Grid>
        <Row center={'xs'} mt>
          <Col xs={12} sm={10} md={8}>
            <H1>Resultados do Questionário </H1>
            <Introduction assesment={assesment} showSeeMore={true} />
            <AssesmentReport assesment={assesment} showSeeMore={true} cta={{ external: false, href: appPaths.subscription.path }} />
          </Col>
        </Row>
        <Separator />
      </Grid>
    </IndexLayout>
  );
};

export default ResultsPage;

export const query = graphql`
  query ResultsQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

////////////////////////////////////////////////////////////////////////////////////////////////////
interface IIntroductionProps {
  assesment?: Assesment;
  showSeeMore: boolean;
}

const Introduction: React.FunctionComponent<IIntroductionProps> = (props) => {
  const assesment = props.assesment;
  if (!assesment) {
    return null;
  }

  return (
    <>
      <ForcedFade show>
        <Row mt mb>
          <Col xs={12}>
            <FeaturedWrapper>
              {assesment.condition ? (
                <EmojiTextVertical
                  emoji="⚠️"
                  text="Você apenas está apto(a) para fazer o programa digital, se antes esclarecermos alguns pontos relevantes em relação à sua condição clínica. Veja a seguir algumas explicações e possibilidades."
                />
              ) : (
                <EmojiTextVertical emoji="🎉" text="Você está apto(a) para o programa!" />
              )}
            </FeaturedWrapper>
          </Col>
        </Row>
      </ForcedFade>
      <ForcedFade show delay=".5s">
        <FeaturedH2>Obrigado por responder nossas questões!</FeaturedH2>

        {assesment.condition ? (
          <Body>
            De acordo com suas respostas, é possível que o programa digital da Vigilantes do Sono não seja o mais indicado para você, pois
            existem algumas técnicas que utilizamos na Vigilantes do Sono que podem não ser ideal de aplicar com condições médicas extras
            específicas. <br />
            No seu caso, recomendamos que o programa seja acompanhado de perto por um psicólogo.
          </Body>
        ) : (
          <Body>
            Existem pessoas para as quais, infelizmente, nosso programa tem algumas limitações ou até contra-indicações, MAS esse não é o
            seu caso! Entenda mais sobre sua insônia, confira a dica que preparamos para você aplicar hoje à noite e clique no botão&quot;
            {AssesmentResultsStartTreatmentTitle}&quot;.
          </Body>
        )}

        {assesment.condition && (
          <>
            <H3>Explicação sobre condição clínica</H3>

            {(assesment.condition.includes(SleepCondition.Bipolar) ||
              assesment.condition.includes(SleepCondition.Parasomnia) ||
              assesment.condition.includes(SleepCondition.Seizure)) && (
              <>
                <Body>
                  <span role="img" aria-label="cérebro">
                    🧠
                  </span>{' '}
                  Se você já recebeu o diagnóstico de{' '}
                  <strong>
                    transtorno bipolar, distúrbios convulsivos ou parassonia grave (comportamentos ou movimentos anormais durante o sono,
                    como sonambulismo ou terror noturno){' '}
                  </strong>
                  , é importante que você esteja ciente que na versão digital da TCC-i da Vigilantes do Sono utilizamos técnicas para
                  melhoria do sono que podem exacerbar os sintomas destas outras condições. Isso não significa que a TCC-i não é um programa
                  adequado para você, mas seria importante você estar sendo acompanhado de perto por um profissional de saúde.
                </Body>
                <SeeMoreBox disabled={!props.showSeeMore}>
                  <Body>
                    Precisamos que você esteja ciente disso e fique atento(a) mais especificamente para o que chamamos de Restrição do Sono.
                    Trata-se de uma técnica da TCC-i que tem a finalidade de &quot;reduzir&quot; ou igualar o tempo que você fica na cama ao
                    tempo que você estiver dormindo. Para a maioria dos casos de pessoas com insônia crônica ou recorrente isso pode
                    significar, inicialmente, uma redução no tempo de sono.
                  </Body>
                  <Body>
                    Não sabemos o quanto você já conversou com um profissional de saúde ou está informado(a) sobre como a redução ou falta
                    de sono pode desencadear alterações importantes de humor e interferir com nossa capacidade de controlar nossas emoções.
                  </Body>
                  <Body>
                    Isso também vale para a ocorrência de convulsões, a privação de sono pode desencadear crises convulsivas em pessoas com
                    epilepsia ou com outras doenças neurológicas. No caso das parassonias graves, a mesma coisa, a falta de sono pode
                    disparar comportamentos anormais durante o sono, mesmo em pessoas sem um diagnóstico ou eventos atuais, que tenham tido
                    apenas sintomas durante a infância ou adolescência de forma intensa.
                  </Body>
                  <Body>
                    Se você não estiver em acompanhamento com um médico ou profissional de saúde, podemos agendar uma entrevista com um de
                    nossos especialistas para esclarecer possíveis dúvidas. Se estiver em acompanhamento, por favor, converse com seu
                    clínico sobre seu desejo de fazer a TCC-i digital aqui da Vigilantes do Sono, e permanecemos à disposição.
                  </Body>
                </SeeMoreBox>
                <Separator />
              </>
            )}
            {assesment.condition.includes(SleepCondition.Apnea) && (
              <>
                <Body>
                  <span role="img" aria-label="nariz">
                    👃
                  </span>{' '}
                  Caso você tenha <strong>apneia obstrutiva do sono (AOS) não tratada</strong>, é possível que sua insatisfação com o sono
                  ou sintomas de insônia estejam relacionados com isso. A AOS é caracterizada por pausas respiratórias normalmente
                  associadas ao relaxamento muscular da garganta que ocorre durante o sono. Um dos principais sinais da AOS é o ronco.
                </Body>
                <SeeMoreBox disabled={!props.showSeeMore}>
                  <Body>
                    Essas pausas respiratórias ocorrem com frequência em pessoas com sobrepeso e obesas - pois há mais acúmulo de gordura
                    favorecendo o estreitamente da via aérea. Também em pessoas que já têm uma anatomia mais estreita mesmo. Esse
                    estreitamento interrompe a passagem de ar e impede uma respiração adequada durante o sono.
                  </Body>
                  <Body>
                    Estas interrupções duram de 10 segundos até mais de 1 minuto em alguns casos, e na maioria das vezes as pessoas nem
                    percebem que despertam, mas elas impedem o descanso adequado e provocam outras doenças a médio e longo prazo, como a
                    própria insônia, além de hipertensão, diabetes, entre outras.
                  </Body>
                </SeeMoreBox>
                <Separator />
                <Body>
                  Existem evidências de que o programa oferecido pela Vigilantes do Sono, a TCC-i, também ajude pessoas com AOS, mas seria
                  importante que você discutisse esta sua condição com um médico, pois nosso programa pode também não funcionar da forma
                  esperada e você pode ficar ainda mais insatisfeito(a).
                </Body>
              </>
            )}
          </>
        )}
      </ForcedFade>
    </>
  );
};

function pushDatalayer(assesment: Assesment) {
  if (isNullOrUndefined(assesment)) {
    return;
  }
  const {
    age,
    frequency: freq,
    challenge,
    time_to_sleep: timeToSleep,
    wake_up_middle: wakeUpMiddle,
    wake_up_early: wakeUpEarly,
    cause,
    solution,
    condition,
    subscribe,
  } = assesment;
  sendDataLayer({
    age,
    freq,
    challenge,
    timeToSleep,
    wakeUpMiddle,
    wakeUpEarly,
    cause,
    solution,
    condition,
    subscribe,
    event: 'results_submit',
  });
}
